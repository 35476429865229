import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';

/**
 * @fero
 */

class UpDownButton extends React.PureComponent {
    static propTypes = {
        onUpClick: PropTypes.func.isRequired,
        onDownClick: PropTypes.func.isRequired,
        upDisabled: PropTypes.bool,
        downDisabled: PropTypes.bool,
    };

    render() {
        const {onUpClick, onDownClick, upDisabled, downDisabled} = this.props;
        return <div className="d-flex flex-column">
            <Button
                disabled={upDisabled}
                onClick={onUpClick}
                icon="up"
                className="up-down-button"
            />
            <Button
                disabled={downDisabled}
                onClick={onDownClick}
                icon="down"
                className="up-down-button"
            />
        </div>;
    }

}

export default UpDownButton;