import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ReportTableSelect from './ReportTableSelect';
import InputText from '../../../../general/InputText';
import ErrorDisplayWrapper from '../../../../general/ErrorDisplayWrapper';
import {REPORT_INIT} from '../ReportEditor';
import {getPartByObjectNavigation} from '../../../../../lib/project';
import {ReportDefinition, ReportTable} from '../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

const rootTableNavigation = ['root'];
const nameNavigation = ['name'];

class ReportTableSelectionWrapped extends React.PureComponent {
    static propTypes = {
        nameLabel: PropTypes.node.isRequired,
        rootTableLabel: PropTypes.node.isRequired,
        report: ReportDefinition.isRequired,
        tables: PropTypes.arrayOf(ReportTable.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        setReport: PropTypes.func.isRequired,
        addToInputCount: PropTypes.func.isRequired,
        addToCorrectInputCount: PropTypes.func.isRequired,
        shouldCheckErrors: PropTypes.bool,
    };

    setCurrentRootTable = (reportTable) => {
        const {setReport, report} = this.props;
        const name = getPartByObjectNavigation(report, nameNavigation);
        const newReport = update(REPORT_INIT, {
            name: {$set: name},
            root: {$set: reportTable}
        });
        setReport(newReport);
    };

    setReportName = (name) => {
        const {updateReport} = this.props;
        updateReport({$set: name}, nameNavigation);
    };

    render() {
        const {
            tables = [], report = {}, rootTableLabel, nameLabel, shouldCheckErrors, addToInputCount, addToCorrectInputCount
        } = this.props;
        const root = getPartByObjectNavigation(report, rootTableNavigation);
        const name = getPartByObjectNavigation(report, nameNavigation);
        return <div className="report-editor-part-wrapper">
            <div className="d-flex align-items-center">
                <h3 className="mr-2 flex-item-static text-dark">
                    {nameLabel}
                </h3>
                <ErrorDisplayWrapper
                    wrapperClassName="full-size-width"
                    shouldCheckErrors={shouldCheckErrors}
                    addToInputCount={addToInputCount}
                    addToCorrectInputCount={addToCorrectInputCount}
                    errorMessage={<Trans>Povinné</Trans>}
                    validator={() => {
                        return name != null;
                    }}
                >
                    <InputText
                        onChange={this.setReportName}
                        value={name}
                    />
                </ErrorDisplayWrapper>
            </div>
            <div className="d-flex align-items-center">
                <h3 className="mr-2 flex-item-static text-dark">
                    {rootTableLabel}
                </h3>
                <div className="flex-item-dynamic-1">
                    <ReportTableSelect
                        tables={tables}
                        onChangeTable={this.setCurrentRootTable}
                        currentTableName={root}
                    />
                </div>
            </div>
        </div>;
    }

}

export default ReportTableSelectionWrapped;