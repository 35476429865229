import React from 'react';
import PropTypes from 'prop-types'
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {Radio} from 'antd';
import {ReportDefinition, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class ReportDefinitionShowSummary extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    updateShowSummary = (e) => {
        const {} = this.props;
        const {navigation, updateReport} = this.props;
        if(e != null && e.target != null) {
            const updateDef = {
                $set: e.target.value
            };
            updateReport(updateDef, navigation);
        }
    };

    render() {
        const {report, navigation} = this.props;
        const showSummary = getPartByObjectNavigation(report, navigation);
        return <div className="report-definition-part-wrapper d-flex align-items-center">
            <h3 className="mr-3">
                <Trans>Zobraziť zhrnutie:</Trans>
            </h3>
            <Radio.Group
                value={showSummary}
                onChange={this.updateShowSummary}
            >
                <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
            </Radio.Group>
        </div>;
    }

}

export default ReportDefinitionShowSummary;