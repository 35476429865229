import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Employee} from '../../constants/propTypesDefinitions';
/**
 * @dusan
 */

class EmployeesSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.EMPLOYEES]: PropTypes.arrayOf(Employee.isRequired).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        onChange: PropTypes.func,
        className: PropTypes.string,
        focus: PropTypes.bool,
    };

    render() {
        const {[GLOBAL_DATA.EMPLOYEES]: employees, focus, value, className, ...props} = this.props;
        const valueChecked = value != null ? (Array.isArray(value) ? value.map(val => Number(val)) : Number(value)) : null;
        return <Select
            className={className}
            {...props}
            focus={focus}
            options={employees.map(user => {
                return {
                    label: user.fullname,
                    value: user.id,
                }
            })}
            value={valueChecked}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.EMPLOYEES])(EmployeesSelect);