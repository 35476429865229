import React from 'react';
import PropTypes from 'prop-types';
import Link from '../navigation/Link';
import Tooltip from '../general/Tooltip';
import locationHOC from '../locationProvider/locationHOC';

/**
 * @fero
 */

class NavigateButton extends React.PureComponent {
    static propTypes = {
        isBackNavigation: PropTypes.bool,
        //if isBackNavigation is true ignores to and queryParams props and navigates to last path(last entry to history)
        tooltipTitle: PropTypes.node,//title to be displayed in tooltip
        to: PropTypes.string,
        queryParams: PropTypes.object,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        size: PropTypes.string,
        type: PropTypes.string,
    };

    render() {
        const {children, to = null, queryParams, type, size, className = "", disabled, tooltipTitle, isBackNavigation} = this.props;
        const content = <div
            className={
                className + " ant-btn d-flex d-inline-flex align-items-center justify-content-center" +
                (type == "primary" ? " ant-btn-primary" : "") +
                (disabled == true ? " disabled-navigate-button" : "") +
                (size == "small" ? " ant-btn-sm" : " px-3")
            }
        >
            <Link
                isBackNavigation={isBackNavigation}
                to={to}
                queryParams={queryParams}
                disabled={disabled}
                className="d-flex align-items-center justify-content-center no-decoration-link"
            >
                {children}
            </Link>
        </div>;
        return tooltipTitle != null ? <Tooltip stopPropagationOnClick={disabled} title={tooltipTitle}>{content}</Tooltip> : content;
        //this is due to some strange bug, when toolset wrapps NavigationButton component it's never visible
    }

}

export default locationHOC(NavigateButton);