import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../../dataProvider/withDataHOC';
import ReportEditor from './ReportEditor';
import {GLOBAL_DATA} from '../../../../constants/globalData'
import {ReportDefinition} from '../../../../constants/propTypesDefinitions'
import {createFetchReportColumns, createFetchReportOperations, createFetchReportTables} from '../../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ReportEditorWrapper extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition,
        action: PropTypes.string,
        onReportAdd: PropTypes.func.isRequired,
        onReportEdit: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.submitButton = null;
        this.state = {
            columns: [],
            operations: [],
            tables: [],
        };
    }

    componentDidMount() {
        this.fetchReportTables();
        this.fetchReportOperations();
    }

    //maybe add depth(needs state and callback and be set from editor)
    fetchReportColumns = (root) => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchReportColumns(),
            {rootName: root},
            (result) => {
                this.setState({
                    columns: result,
                })
            }
        )
    };

    fetchReportTables = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchReportTables(),
            {},
            (result) => {
                this.setState({
                    tables: result,
                })
            }
        )
    };

    fetchReportOperations = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchReportOperations(),
            {},
            (result) => {
                this.setState({
                    operations: result,
                })
            }
        )
    };

    render() {
        const {onReportAdd, onReportEdit, report, action} = this.props;
        const {tables, columns, operations} = this.state;
        return <ReportEditor
            columns={columns}
            operations={operations}
            tables={tables}
            reloadColumns={this.fetchReportColumns}
            report={report}
            onReportEdit={onReportEdit}
            onReportAdd={onReportAdd}
            action={action}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(ReportEditorWrapper);