import React from 'react';
import PropTypes from 'prop-types';
import ReportDefinitionColumns from './reportDefinition/ReportDefinitionColumns';
import ReportDefinitionFilters from './reportDefinition/ReportDefinitionFilters';
import ReportDefinitionGroupBy from './reportDefinition/ReportDefinitionGroupBy';
import ReportDefinitionPostFilters from './reportDefinition/ReportDefinitionPostFilters';
import ReportDefinitionOrderBy from './reportDefinition/ReportDefinitionOrderBy';
import ReportDefinitionShowSummary from './reportDefinition/ReportDefinitionShowSummary';
import InputText from '../../../../general/InputText';
import {ReportDefinition as ReportDefinitionProp, ReportOperation, ReportColumn} from '../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import ReportDefinitionCompareBy from './reportDefinition/ReportDefinitionCompareBy';
import ReportDefinitionOwner from './reportDefinition/ReportDefinitionOwner';
import ReportDefinitionPagination from './reportDefinition/ReportDefinitionPagination';

/**
 * @fero
 */

class ReportDefinition extends React.PureComponent {
    static propTypes = {
        report: ReportDefinitionProp.isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        shouldCheckErrors: PropTypes.bool,
        addToInputCount: PropTypes.func.isRequired,
        addToCorrectInputCount: PropTypes.func.isRequired,
    };

    render() {
        const {report, columns, operations, updateReport, addToInputCount, shouldCheckErrors, addToCorrectInputCount} = this.props;
        return <div className="report-editor-part-wrapper">
            <ReportDefinitionColumns
                navigation={['columns']}
                report={report}
                operations={operations}
                columns={columns}
                updateReport={updateReport}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
            />
            <ReportDefinitionFilters
                navigation={['filter']}
                report={report}
                operations={operations}
                columns={columns}
                updateReport={updateReport}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
            />
            <ReportDefinitionGroupBy
                report={report}
                columns={columns}
                navigation={['key_columns']}
                updateReport={updateReport}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
            />
            <ReportDefinitionCompareBy
                report={report}
                navigation={['compare_column']}
                updateReport={updateReport}
            />
            <ReportDefinitionPostFilters
                navigation={['post_filter']}
                report={report}
                operations={operations}
                columns={columns}
                updateReport={updateReport}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
            />
            <ReportDefinitionOrderBy
                report={report}
                columns={columns}
                navigation={['order_columns']}
                updateReport={updateReport}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
            />
            <ReportDefinitionPagination
                report={report}
                columns={columns}
                navigation={['pagination_column']}
                updateReport={updateReport}
            />
            <ReportDefinitionShowSummary
                report={report}
                columns={columns}
                navigation={['show_summary']}
                updateReport={updateReport}
            />
            <ReportDefinitionOwner
                report={report}
                navigation={['id_owner']}
                updateReport={updateReport}
            />
        </div>;
    }

}

export default ReportDefinition;