import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ReportSubmit from './reportEditor/ReportSubmit';
import ReportTableSelectionWrapped from './reportEditor/ReportTableSelectionWrapped';
import ReportDefinition from './reportEditor/ReportDefinition';
import {ReportDefinition as ReportDefinitionProp, ReportColumn, ReportOperation, ReportTable} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

export const COLUMN_KEY = 'col';
export const OPERATION_KEY = 'operation';
export const VALUE_KEY = 'val';
export const INPUTS_KEY = 'inputs';
export const TYPES = {
    [COLUMN_KEY]: {
        label: <Trans render={null}>Položka</Trans>,
        key: COLUMN_KEY,
    },
    [OPERATION_KEY]: {
        label: <Trans render={null}>Operácia</Trans>,
        key: OPERATION_KEY,
    },
    [VALUE_KEY]: {
        label: <Trans render={null}>Hodnota</Trans>,
        key: VALUE_KEY,
    }
};

export const REPORT_INIT = {
    name: null,
    root: null,
    columns: [{formula: {[COLUMN_KEY]: null}}],
    filter: null,
    param: null,
    key_columns: [],
    order_columns: [],
    args: null,
    show_summary: 0,
};

class ReportEditor extends React.PureComponent {
    static propTypes = {
        report: ReportDefinitionProp,
        action: PropTypes.string,
        onReportAdd: PropTypes.func.isRequired,
        onReportEdit: PropTypes.func.isRequired,
        reloadColumns: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        tables: PropTypes.arrayOf(ReportTable.isRequired).isRequired,
    };

    constructor(props) {
        super(props);
        this.submitButton = null;
        this.state = {
            reportInEditing: props.report != null ? this.setEditorDefaultsToExistingReport(props.report) : REPORT_INIT,
            shouldSubmit: false,
            shouldCheckErrors: false,
            inputCount: 0,
            correctInputCount: 0,
        };
    }

    componentDidMount() {
        const {reloadColumns} = this.props;
        const {reportInEditing} = this.state;
        const {root} = reportInEditing;
        if (root != null) {
            reloadColumns(root);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {report, reloadColumns} = this.props;
        const {report: prevReport}  = prevProps;
        if (report != prevReport)
        {
            if (report != null && report.root != null) {
                this.setState(
                    {
                        reportInEditing: this.setEditorDefaultsToExistingReport(report)
                    }, 
                    () => {
                        reloadColumns(this.state.reportInEditing.root)
                    }
                );
            }
            else 
            {
                this.setState({
                    reportInEditing: REPORT_INIT,
                });
            }
        } 
        else 
        {
            const {reportInEditing} = this.state;
            const {reportInEditing: prevReportInEditing = {}} = prevState;
            const {root} = reportInEditing;
            const {root: prevRoot} = prevReportInEditing;
            if (root != null && root != prevRoot)
                reloadColumns(root);
        }
        
    }

    setShouldCheckErrors = (should) => {
        this.setState({
            shouldCheckErrors: should,
        })
    };

    setShouldSubmit = (should) => {
        this.setState({
            shouldSubmit: should,
        })
    };

    addToInputCount = (toAdd) => {
        this.setState((state) => {
            return {
                shouldSubmit: false,
                shouldCheckErrors: false,
                inputCount: (state.inputCount + toAdd)
            };
        })
    };

    addToCorrectInputCount = (toAdd) => {
        this.setState((state) => {
            return {correctInputCount: (state.correctInputCount + toAdd)};
        })
    };

    resetCorrectInputCount = () => {
        this.setState({correctInputCount: 0});
    };

    setEditorDefaultsToExistingReport = (report) => {
        let updateObj = {};
        if (report.columns == null) {
            updateObj.columns = {$set: []};
        }
        if (report.key_columns == null) {
            updateObj.key_columns = {$set: []};
        }
        if (report.order_columns == null) {
            updateObj.order_columns = {$set: []};
        }
        return update(report, updateObj);
    };

    updateReportInEditing = (updateDef, navigation) => {
        const {reportInEditing} = this.state;
        let updateObj = updateDef;
        if (navigation.length != null && navigation.length > 0 && !Number.isNaN(Number(navigation.length))) {
            let i = (navigation.length - 1);
            while (i >= 0) {
                let tmp = {};
                tmp[navigation[i]] = updateObj;
                updateObj = tmp;
                i--;
            }
        }
        const updatedReport = update(reportInEditing, updateObj);

        this.setState({
            shouldCheckErrors: false,
            reportInEditing: updatedReport,
        });
    };

    setReportInEditing = (report) => {
        this.setState({
            reportInEditing: report,
        });
    };

    render() {
        const {report, onReportAdd, onReportEdit, tables, operations, columns, action} = this.props;
        const {
            reportInEditing, shouldCheckErrors, correctInputCount, inputCount, shouldSubmit,
        } = this.state;

        const reportId = report != null ? report.id : null;
        const reportExists = reportId != null && action != 'copy';//define whether report should be added or edited

        return <div className="overflow-auto flex-item-dynamic-1">
            <ReportTableSelectionWrapped
                rootTableLabel={<Trans>Report pre dáta : </Trans>}
                nameLabel={<Trans>Názov reportu : </Trans>}
                tables={tables}
                updateReport={this.updateReportInEditing}
                setReport={this.setReportInEditing}
                report={reportInEditing}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={this.addToInputCount}
                addToCorrectInputCount={this.addToCorrectInputCount}
            />
            {reportInEditing.root != null ? <ReportDefinition
                updateReport={this.updateReportInEditing}
                report={reportInEditing}
                operations={operations}
                columns={columns}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={this.addToInputCount}
                addToCorrectInputCount={this.addToCorrectInputCount}
            /> : null}
            {reportInEditing.root != null ? <div
                onClick={() => {
                    this.resetCorrectInputCount();
                    this.setShouldCheckErrors(true);
                    this.setShouldSubmit(true);
                }}
            >
                <ReportSubmit
                    ref={input => this.submitButton = input}
                    action={action}
                    reportId={reportId}
                    shouldSubmit={shouldSubmit && (inputCount == correctInputCount)}
                    report={reportInEditing}
                    onReportSave={(reportId) => {
                        reportExists ? onReportEdit() : onReportAdd(reportId);
                    }}
                    afterReportSubmit={() => {
                        this.setShouldSubmit(false);
                        this.setShouldCheckErrors(false);
                    }}
                />
            </div> : null
            }
        </div>;
    }

}

export default ReportEditor;