import React from 'react';
import PropTypes from 'prop-types';
import ReportDefinitionFormulaInput from './ReportDefinitionFormulaInput';
import InputText from '../../../../../general/InputText';
import UpDownButton from '../../../../../general/UpDownButton';
import {COLUMN_KEY, OPERATION_KEY, VALUE_KEY} from '../../ReportEditor';
import {Button} from 'antd'
import {moveDown, moveUp} from '../../../../../../lib/array';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {ReportDefinition, ReportOperation, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ReportDefinitionColumns extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        shouldCheckErrors: PropTypes.bool,
        addToInputCount: PropTypes.func.isRequired,
        addToCorrectInputCount: PropTypes.func.isRequired,
    };

    addColumn = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $push: [{
                formula: {[COLUMN_KEY]: null,}
            }]
        };
        updateReport(updateDef, navigation);
    };

    removeColumn = (index) => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $splice: [[index, 1]]
        };
        updateReport(updateDef, navigation);
    };

    setLabel = (label, navigation) => {
        const {updateReport} = this.props;
        const updateDef = {
            $set: label,
        };
        updateReport(updateDef, navigation);
    };

    moveColumnUp = (index) => {
        const {updateReport, navigation, report} = this.props;
        const reportColumns = getPartByObjectNavigation(report, navigation);
        const newColumns = moveUp(reportColumns, index);
        const updateDef = {
            $set: newColumns,
        };
        updateReport(updateDef, navigation);
    };

    moveColumnDown = (index) => {
        const {updateReport, navigation, report} = this.props;
        const reportColumns = getPartByObjectNavigation(report, navigation);
        const newColumns = moveDown(reportColumns, index);
        const updateDef = {
            $set: newColumns,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {columns, operations, report, updateReport, navigation, addToInputCount, shouldCheckErrors, addToCorrectInputCount} = this.props;

        const reportColumns = getPartByObjectNavigation(report, navigation);
        const reportColumnsChecked = reportColumns != null ? reportColumns : [];
        const moreThanOne = reportColumnsChecked.length > 1;

        return <div className="report-definition-part-wrapper">
            <h3>
                <Trans>Položky:</Trans>
            </h3>
            {reportColumnsChecked.map((reportColumn, index) => {
                let newNavigation = [...navigation];
                newNavigation.push((index + ''));
                let formulaNavigation = [...newNavigation];
                formulaNavigation.push('formula');
                let labelNavigation = [...newNavigation];
                labelNavigation.push('label');
                return <ReportDefinitionFormulaInput
                    key={index}
                    operations={operations}
                    columns={columns}
                    report={report}
                    navigation={formulaNavigation}
                    updateReport={updateReport}
                    onDelete={moreThanOne ? () => {
                        this.removeColumn(index)
                    } : undefined}
                    addToInputCount={addToInputCount}
                    shouldCheckErrors={shouldCheckErrors}
                    addToCorrectInputCount={addToCorrectInputCount}
                    validator={() => {
                        const def = getPartByObjectNavigation(report, formulaNavigation);
                        const label = getPartByObjectNavigation(report, labelNavigation);
                        return label != null && def != null &&
                            (def[COLUMN_KEY] != null || def[OPERATION_KEY] != null || def[VALUE_KEY] != null);
                    }}
                    errorMessage={<Trans>Povinné</Trans>}
                    before={[
                        <UpDownButton
                            key="upDown"
                            onDownClick={() => {this.moveColumnDown(index)}}
                            onUpClick={() => {this.moveColumnUp(index)}}
                            upDisabled={index == 0}
                            downDisabled={index == (reportColumnsChecked.length - 1)}
                        />,
                        <InputText
                            key="inputText"
                            className="report-column-label"
                            placeholder={'Názov...'}
                            value={getPartByObjectNavigation(report, labelNavigation)}
                            onChange={(label) => {
                                this.setLabel(label, labelNavigation)
                            }}
                        />
                    ]}
                />
            })}
            <Button
                onClick={this.addColumn}
            >
                <Trans>Pridať</Trans>
            </Button>
        </div>;
    }

}

export default ReportDefinitionColumns;