import React from 'react';
import PropTypes from 'prop-types';
import ReportDefinitionColumnSelect from './ReportDefinitionColumnSelect';
import ReportDefinitionInput from './ReportDefinitionFormulaInput';
import ErrorDisplayWrapper from '../../../../../general/ErrorDisplayWrapper';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {Button} from 'antd'
import {ReportDefinition, ReportOperation, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ReportDefinitionGroupBy extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        shouldCheckErrors: PropTypes.bool,
        addToInputCount: PropTypes.func.isRequired,
        addToCorrectInputCount: PropTypes.func.isRequired,
    };

    addGroupByColumn = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $push: [null]
        };
        updateReport(updateDef, navigation);
    };

    removeGroupByColumn = (index) => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $splice: [[index, 1]]
        };
        updateReport(updateDef, navigation);
    };

    updateGroupByColumn = (columnName, navigation) => {
        const {updateReport} = this.props;
        const updateDef = {
            $set: columnName,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {columns, report, navigation, shouldCheckErrors, addToInputCount, addToCorrectInputCount} = this.props;

        const groupByColumns = getPartByObjectNavigation(report, navigation);
        const groupByColumnsChecked = groupByColumns != null ? groupByColumns : [];

        const reportColumns = getPartByObjectNavigation(report, ['columns']);
        const reportColumnsWithNames = reportColumns.map(col => { return {name: col.label, label: col.label}; });
        const allColumns = reportColumnsWithNames.concat(columns);

        return <div className="report-definition-part-wrapper">
            <h3>
                <Trans>Zoskupiť podľa:</Trans>
            </h3>
            {groupByColumnsChecked.map((groupByColumn, index) => {
                let newNavigation = [...navigation];
                newNavigation.push((index + ''));
                const columnName = getPartByObjectNavigation(report, newNavigation);
                return <ReportDefinitionColumnSelect
                    key={index}
                    columns={allColumns}
                    onChange={(columnName) => {
                        this.updateGroupByColumn(columnName, newNavigation)
                    }}
                    onDelete={() => {
                        this.removeGroupByColumn(index)
                    }}
                    selectedColumnName={columnName}
                    shouldCheckErrors={shouldCheckErrors}
                    addToInputCount={addToInputCount}
                    addToCorrectInputCount={addToCorrectInputCount}
                    validator={(props) => {
                        return columnName != null;
                    }}
                    errorMessage={<Trans>Povinné</Trans>}
                />;
            })}
            <Button
                onClick={this.addGroupByColumn}
            >
                <Trans>Pridať</Trans>
            </Button>
        </div>;
    }

}

export default ReportDefinitionGroupBy;