import React from 'react';
import PropTypes from 'prop-types';
import ButtonFetch from '../../../../fetch/ButtonFetch';
import generalFailedPC from '../../../../fetch/generalFailedPC';
import {createFetchReportAdd, createFetchReportEdit} from '../../../../../backend/apiCalls';
import {ReportDefinition} from '../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * Submits report to server. If reportId is defined edits existing report. When it's not creates new one.
 *
 * @fero
 */

class ReportCommit extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        action: PropTypes.string,
        reportId: PropTypes.number,
        onReportSave: PropTypes.func.isRequired,
        afterReportSubmit: PropTypes.func.isRequired,
        shouldSubmit: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.submitButton = null;

    }

    submit = () => {
        if(this.submitButton != null && this.submitButton.handleSubmit != null) {
            this.submitButton.handleSubmit();
        }
    };

    render() {
        const {report, action, reportId, onReportSave, shouldSubmit, afterReportSubmit} = this.props;
        const fetchFunc =  (reportId == null || action == 'copy') ?
            createFetchReportAdd(report) :
            createFetchReportEdit(reportId, report);
        return <div className="report-editor-part-wrapper d-flex align-items-center justify-content-center">
            <ButtonFetch
                ref={input => this.submitButton = input}
                type="primary"
                shouldFetch={shouldSubmit}
                fetchFunction={fetchFunc}
                values={{}}
                onFinish={onReportSave}
                onAfterFetch={afterReportSubmit}
                Response={ReportSaveResponse}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť report.`)}
            >
                <Trans>Uložiť</Trans>
            </ButtonFetch>
        </div>;
    }

}

export default ReportCommit

class ReportSaveResponse extends  React.PureComponent {
    render() {
        return <h4>
            {t`Report bol úspešne uložený.`}
        </h4>
    }
}