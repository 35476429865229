import React from 'react';
import errorDisplayHOC from './errorDisplayHOC';

/**
 * Component that enables use errorDisplayHOC as a container component.
 * In this case props are not transfered as parameter to validator function.
 *
 * @fero
 *
 */
class ErrorDisplayWrapper extends React.PureComponent {

    render() {
        const {children} = this.props;
        return children;
    }
}
export default errorDisplayHOC(ErrorDisplayWrapper);