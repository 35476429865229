import React from 'react';
import PropTypes from 'prop-types';
import ReportDefinitionFormulaInput from './ReportDefinitionFormulaInput';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {COLUMN_KEY, OPERATION_KEY, VALUE_KEY} from '../../ReportEditor';
import {Button} from 'antd'
import {ReportDefinition, ReportOperation, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ReportDefinitionFilters extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        shouldCheckErrors: PropTypes.bool,
        addToInputCount: PropTypes.func.isRequired,
        addToCorrectInputCount: PropTypes.func.isRequired,
    };

    addFilter = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $set: {
                [OPERATION_KEY]: null,
            }
        };
        updateReport(updateDef, navigation);
    };

    removeFilter = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $set: null,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {columns, operations, report, updateReport, navigation, addToInputCount, shouldCheckErrors, addToCorrectInputCount} = this.props;
        const filter = getPartByObjectNavigation(report, navigation);
        const operationsFiltered = operations.filter(op => !op.is_aggregate);
        return <div className="report-definition-part-wrapper">
            <h3>
                <Trans>Podmienka:</Trans>
            </h3>
            {filter != null ? <ReportDefinitionFormulaInput
                report={report}
                operations={operationsFiltered}
                columns={columns}
                updateReport={updateReport}
                navigation={navigation}
                onDelete={this.removeFilter}
                shouldCheckErrors={shouldCheckErrors}
                addToInputCount={addToInputCount}
                addToCorrectInputCount={addToCorrectInputCount}
                validator={() => {
                    const def = getPartByObjectNavigation(report, navigation);
                    return def != null &&
                        (def[COLUMN_KEY] != null || def[OPERATION_KEY] != null || def[VALUE_KEY] != null);
                }}
                errorMessage={<Trans>Povinné</Trans>}
            /> : <Button
                onClick={this.addFilter}
            >
                <Trans>Pridať</Trans>
            </Button>}
        </div>;
    }

}

export default ReportDefinitionFilters;