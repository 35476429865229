import React from 'react';
import PropTypes from 'prop-types';

/**
 * HOC for display of error messages.
 * When shouldCheck is true validator function is called on render. If it doesn't return true
 * component is wrapped into styleable div and on bottom of this div an error message is displayed.
 * After render if shouldCheck has changed from false to true addToCorrectInputCount is called with attribute 1.
 *
 * @fero
 *
 */

const errorDisplayHOC = (Component) => {
    class ErrorDisplayWrapper extends React.PureComponent {
        static propTypes = {
            wrapperClassName: PropTypes.string,
            messageWrapperClassName: PropTypes.string,
            errorMessage: PropTypes.node,
            shouldCheckErrors: PropTypes.bool,
            addToInputCount: PropTypes.func.isRequired,
            addToCorrectInputCount: PropTypes.func.isRequired,
            validator: PropTypes.func.isRequired,
        };

        componentDidMount() {
            const {addToInputCount} = this.props;
            if (addToInputCount != null) {
                addToInputCount(1);
            }
        }

        componentWillUnmount() {
            const {addToInputCount} = this.props;
            if (addToInputCount != null) {
                addToInputCount(-1);
            }
        }

        componentDidUpdate(prevProps) {
            const {validator, addToCorrectInputCount, shouldCheckErrors} = this.props;
            const {shouldCheckErrors: prevShouldCheckErrors} = prevProps;
            if (shouldCheckErrors == true && prevShouldCheckErrors == false) {
                const currentlyValid = validator(this.props);
                if (currentlyValid) {
                    addToCorrectInputCount(1);
                }
            }
        }

        render() {
            const {shouldCheckErrors, errorMessage, wrapperClassName, messageWrapperClassName, validator} = this.props;
            if (shouldCheckErrors == true) {
                if (validator(this.props)) {
                    return <Component {...this.props}/>;
                } else {
                    return <div className={wrapperClassName != null ? wrapperClassName : ''}>
                        <Component {...this.props}/>
                        <div
                            className={'error-text-color ' + (messageWrapperClassName != null ? messageWrapperClassName : '')}>
                            {errorMessage}
                        </div>
                    </div>
                }
            } else {
                return <Component {...this.props}/>;
            }
        }
    }
    return ErrorDisplayWrapper;
};

export default errorDisplayHOC;