import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import ReportEditorWrapper from './report/manageReports/ReportEditorWrapper';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import { getQueryParam, getQueryParamNumber, navigateToParametrized} from '../../lib/url';
import { ReportDefinitions } from '../../constants/propTypesDefinitions';
import PageActionsLayout from '../general/PageActionsLayout';
import {Trans, t} from '@lingui/macro';
import DisplayReportButton from './report/reportList/DisplayReportButton';
import ExportReportButton from './report/reportList/ExportReportButton';
import RemoveReportButton from './report/reportList/RemoveReportButton';

/**
 * @fero
 */

class ManageReportPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.REPORTS]: ReportDefinitions.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    
    getCurrentReport = () => {
        const {[GLOBAL_DATA.REPORTS]: reports, location} = this.props;
        const reportId = getQueryParamNumber(location, QUERY_PARAMS.ID_REPORT);
        return reports.find(report => report.id == reportId);
    }

    onReportAdd = (reportId) => {
        const {location, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        reload([GLOBAL_DATA.REPORTS]);
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ID_REPORT]: reportId, 
            [QUERY_PARAMS.REPORT_ACTION]: 'edit'
        });
    };

    onReportEdit = () => {
        const {[GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        reload([GLOBAL_DATA.REPORTS]);
    };

    onReportRemove = () => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.REPORTS, {
            [QUERY_PARAMS.ID_REPORT]: undefined, 
            [QUERY_PARAMS.REPORT_ACTION]: undefined
        });
    }

    render() {
        const {location} = this.props;
        const act = getQueryParam(location, QUERY_PARAMS.REPORT_ACTION);
        const currentReport = this.getCurrentReport();
        const reportName = currentReport ? currentReport.name : '';
        return <React.Fragment>
            <Helmet
                title={act == 'copy' ?
                    t`Kópia reportu ` + reportName
                    :
                    t`Úprava reportu ` + reportName
                }
            />
            <div className="full-size-width full-size-height d-flex flex-column">
                <div className="flex-item-static">
                    <PageActionsLayout
                        back={{
                            to: ROUTES.REPORTS,
                            title: <Trans>Späť</Trans>,
                        }}
                        actions={currentReport != null ? [
                            {
                                node: <ExportReportButton
                                    reportId={currentReport.id}
                                />
                            },
                            {
                                node: <RemoveReportButton
                                    reportId={currentReport.id}
                                    onFinish={this.onReportRemove}
                                />
                            },
                            {
                                node: <DisplayReportButton
                                    reportId={currentReport.id}
                                />
                            },
                        ] : null}
                    />
                    { currentReport != null ?
                        <h2 className="text-center">
                            { act == 'copy' ?
                                <Trans>Kópia reportu</Trans> 
                                : 
                                <Trans>Úprava reportu</Trans>
                            }
                            {' '}
                            {currentReport.name}
                        </h2> :
                        <h2>Nový report</h2>
                    }
                </div>
                <ReportEditorWrapper
                    report={currentReport}
                    action={act}
                    onReportEdit={this.onReportEdit}
                    onReportAdd={this.onReportAdd}
                />
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.REPORTS, GLOBAL_DATA.RELOAD_DATA])(ManageReportPage)
);