import React from 'react';
import PropTypes from 'prop-types'
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {ReportDefinition, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import EmployeesSelect from '../../../../../project/EmployeesSelect';
/**
 * @fero
 */

class ReportDefinitionOwner extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    updateOwner = (ownerId) => {
        const {} = this.props;
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $set: ownerId
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {report, navigation} = this.props;
        const ownerId = getPartByObjectNavigation(report, navigation);
        return <div className="report-definition-part-wrapper d-flex align-items-center">
            <h3 className="mr-3">
                <Trans>Vlastník reportu:</Trans>
            </h3>
            <EmployeesSelect
                className="report-value-input d-inline-block my-1"
                value={ownerId}
                onChange={this.updateOwner}
                allowClear={true}
                placeholder={<Trans>Všetci</Trans>}
            />
        </div>;
    }

}

export default ReportDefinitionOwner;