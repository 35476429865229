import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../general/Select';
import {ReportTable} from '../../../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ReportTableSelect extends React.PureComponent {
    static propTypes = {
        currentTableName: PropTypes.string,
        tables: PropTypes.arrayOf(ReportTable.isRequired).isRequired,
        onChangeTable: PropTypes.func.isRequired,
    };

    render() {
        const {currentTableName, tables, onChangeTable} = this.props;
        return <Select
            onChange={onChangeTable}
            options={tables.map(table => {
                return {
                    label: table.label,
                    value: table.name,
                }
            })}
            value={currentTableName}

        />;
    }

}

export default ReportTableSelect;