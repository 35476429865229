import React from 'react';
import PropTypes from 'prop-types';
import {getDomain, addQueryParamsToUrl} from '../../../../lib/url';
import {EXPORT_REPORT_TARGET} from '../../../../constants/apiEndpoints';
import {Button, Icon} from 'antd';
import Tooltip from '../../../general/Tooltip';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ExportReportButton extends React.PureComponent {
    static propTypes = {
        reportId: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        size: PropTypes.string,
    };

    render() {
        const {reportId, disabled, size, ...props} = this.props;
        let queryParams = {id: reportId};
        const url = addQueryParamsToUrl(getDomain() + EXPORT_REPORT_TARGET, queryParams);
        return <Tooltip stopPropagationOnClick={disabled} title={<Trans>Stiahnuť report</Trans>}>
            <a href={url}>
                <Button
                    disabled={disabled}
                    size={size}
                    {...props}
                >
                    <Icon type="download"/>
                    { size != 'small' ?
                        <span className="ml-2">
                            <Trans>Stiahnuť report</Trans>
                        </span> :
                        null
                    }
                </Button>
            </a>
        </Tooltip>;
    }

}

export default ExportReportButton;