import React from 'react';
import PropTypes from 'prop-types';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {ReportDefinition} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Select from '../../../../../general/Select';

/**
 * @fero
 */

class ReportDefinitionCompareBy extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    updateCompareByColumn = (columnName) => {
        const {updateReport, navigation} = this.props;
        const updateDef = {
            $set: columnName,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {report, navigation} = this.props;

        const compareByCol = getPartByObjectNavigation(report, navigation);
        const reportColumns = getPartByObjectNavigation(report, ['columns']);
        const reportColumnsWithNames = reportColumns.map(col => { return {label: col.label, value: col.label}; });

        return <div className="report-definition-part-wrapper d-flex align-items-center">
            <h3 className="mr-3">
                <Trans>Porovnať podľa:</Trans>
            </h3>
            <Select
                className="report-value-input d-inline-block my-1"
                onChange={this.updateCompareByColumn}
                options={reportColumnsWithNames}
                value={compareByCol}
                allowClear={true}
            />
        </div>;
    }

}

export default ReportDefinitionCompareBy;