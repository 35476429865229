import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import NavigateButton from '../../../general/NavigateButton';
import { ROUTES, QUERY_PARAMS } from '../../../../constants/navigation';

/**
 * @fero
 */

class DisplayReportButton extends React.PureComponent {
    static propTypes = {
        reportId: PropTypes.number.isRequired,
        size: PropTypes.string,
    };

    render() {
        const {reportId, size, ...props} = this.props;
        return <NavigateButton
            to={ROUTES.REPORT_DISPLAY}
            queryParams={{[QUERY_PARAMS.ID_REPORT]: reportId}}
            tooltipTitle={<Trans>Zobraziť report</Trans>}
            size={size}
            icon="unordered-list"
            {...props}
        >
            <Icon type="unordered-list"/>
            { size != 'small' ?
                <span className="ml-2">
                    <Trans>Zobraziť report</Trans>
                </span> :
                null
            }
        </NavigateButton>;
    }

}

export default DisplayReportButton;