import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import NavigateButton from './NavigateButton';
import { RIGHTS } from '../../constants/Rights';
import { Button, Dropdown, Icon, Menu } from 'antd';

/**
 * @dusan
 */

class PageActionsLayout extends React.PureComponent {
    static propTypes = {
        back: PropTypes.shape({
            to: PropTypes.string,
            title: PropTypes.node.isRequired,
            useDefault: PropTypes.bool,
        }),
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                node: PropTypes.node,
                rightsFrom: PropTypes.number,
                rightsTo: PropTypes.number,
            }),
        ),
        [SESSION_ATTRIBUTES.IS_MOBILE]: PropTypes.bool,
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
        };  
    };

    toggleMenuVisibility = () => {
        const {menuVisible} = this.state;
        this.setState({menuVisible: !menuVisible});
    };

    closeMenu = () => {
        this.setState({menuVisible: false});
    };

    setMenuVisibility = (val) => {
        this.setState({menuVisible: val});
    };

    render() {
        const {
            back, actions, 
            [SESSION_ATTRIBUTES.RIGHTS]: rights, 
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile
        } = this.props;

        const {menuVisible} = this.state;

        const actionsFiltered = actions != null && Array.isArray(actions) ? actions.filter(obj => {
            const rightsMin = obj.rightsFrom != null ? obj.rightsFrom : RIGHTS.LOCKED;
            const rightsMax = obj.rightsTo != null ? obj.rightsTo : RIGHTS.ADMIN;
            return (rights >= rightsMin && rights <= rightsMax && obj.node != null);
        }) : [];

        return <div className={"pt-2 d-flex " + (back != null ? "justify-content-between" : "justify-content-end")}>
            { back != null ?
                <NavigateButton
                    to={back.to}
                    isBackNavigation={back.useDefault}
                    className="m-1"
                >
                    <Icon type="left"/>
                    {back.title}
                </NavigateButton> :
                null
            }
            { isMobile && actionsFiltered.length > 1 ? 
                <div className="d-flex justify-content-end">
                    <div className="m-1">
                        {actionsFiltered[actionsFiltered.length-1].node}
                    </div> 
                    <Dropdown
                        overlay={
                            <Menu onClick={this.closeMenu}>
                            {
                                actionsFiltered.map((obj, idx) => {
                                    return idx < actionsFiltered.length-1 ?
                                        <Menu.Item key={idx} className="page-actions-dropdown-item">
                                            {obj.node}
                                        </Menu.Item> : null
                                })
                            }
                            </Menu>
                        }
                        visible={menuVisible}
                        onVisibleChange={this.setMenuVisibility}
                    >
                        <Button className="m-1 page-actions-dropdown-button" onClick={this.toggleMenuVisibility}>
                            <Icon type="ellipsis"/>
                        </Button>
                    </Dropdown>
                </div> :
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                { actionsFiltered.map((obj, idx) => {
                        return <div className="m-1" key={idx}>{obj.node}</div> 
                    })
                }
                </div>
            }
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.IS_MOBILE])(PageActionsLayout);