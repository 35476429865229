import React from 'react';
import PropTypes from 'prop-types';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {ReportDefinition} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Select from '../../../../../general/Select';

/**
 * @dusan
 */

class ReportDefinitionPagination extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    updatePaginationCol = (columnName) => {
        const {updateReport, navigation} = this.props;
        const updateDef = {
            $set: columnName,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {report, columns, navigation} = this.props;

        const paginationCol = getPartByObjectNavigation(report, navigation);

        return <div className="report-definition-part-wrapper d-flex align-items-center">
            <h3 className="mr-3">
                <Trans>Stránkovať podľa:</Trans>
            </h3>
            <Select
                className="report-value-input d-inline-block my-1"
                onChange={this.updatePaginationCol}
                options={columns.map(column => {
                    return {
                        label: column.label,
                        value: column.name,
                    }
                })}
                value={paginationCol}
                allowClear={true}
            />
        </div>;
    }

}

export default ReportDefinitionPagination;